import * as React from 'react'
import { generateMonth } from '/src/functions/timeFormatter'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/Form/Select'))

const SelectMonth = ({ name, isSubmitting, isSubmitted, className }) => {
    const months = Array.from({ length: 12 }, (item, i) => {
        return generateMonth(new Date(0, i))
    })

    return (
        <Select
            className={className}
            name={name}
            id={name}
            firstItem={'Select month'}
            data={months}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

export default SelectMonth
